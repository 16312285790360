import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/seo"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 512, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <>
      <Seo title="404: Not found" />
      <div className="container py-20 md:py-24 lg:py-32 xl:py-40 h-100">
        <div className="flex items-center justify-center">
          <div className="flex flex-col items-center">
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              className="w-64 h-auto"
              alt="Not found"
            />
            <p className="pt-5 text-lg md:text-2xl text-center text-gray-700">
              The server could not find what was requested
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
